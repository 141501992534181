<template>
  <section class="login">
    <Loader :loading="showLoader" />
    <div class="container-scroller">
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-center auth">
          <div class="row w-100 flex-grow">
            <div class="col-xl-4 col-lg-6 mx-auto">
              <div class="auth-form-light text-left p-4">
                <div class="brand-logo">
                  <img src="../../assets/images/logo-new.png" />
                </div>
                <h4>Hello! let's get started</h4>
                <h6 class="font-weight-light">Sign in to continue.</h6>
                <form @submit.prevent="signIn">
                  <div class="form-group">
                    <label for="email">Email</label>
                    <input
                      type="email"
                      v-model="user.email"
                      id="email"
                      name="email"
                      class="form-control form-control-lg"
                      required
                      :class="{
                        'is-invalid': submitted && $v.user.email.$error,
                      }"
                    />
                    <div
                      v-if="submitted && $v.user.email.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.user.email.required"
                        >Email is required</span
                      >
                      <span v-if="!$v.user.email.email">Email is invalid</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="password">Password</label>
                    <input
                      type="password"
                      v-model="user.password"
                      id="password"
                      name="password"
                      class="form-control form-control-lg"
                      :class="{
                        'is-invalid': submitted && $v.user.password.$error,
                      }"
                    />
                    <div
                      v-if="submitted && $v.user.password.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.user.password.required"
                        >Password is required</span
                      >
                      <span v-if="!$v.user.password.minLength"
                        >Password must be at least 9 characters</span
                      >
                    </div>
                  </div>
                  <div
                    class="
                      my-2
                      d-flex
                      justify-content-between
                      align-items-center
                    "
                  >
                    <div class="form-check">
                      <label class="form-check-label text-muted">
                        <input type="checkbox" class="form-check-input" />
                        Keep me signed in
                        <i class="input-helper"></i>
                      </label>
                    </div>
                    <div class="auth-link text-black">
                      <router-link to="/auth-pages/forgot" class="text-primary"
                        >Forgot password?</router-link
                      >
                    </div>
                  </div>
                  <div class="form-group">
                    <button
                      class="
                        btn btn-block btn-gradient-primary btn-lg
                        font-weight-medium
                        auth-form-btn
                      "
                    >
                      Login
                    </button>
                    <!-- <div class="text-center mt-4 font-weight-light">
                      Already have an account?
                      <router-link
                        to="/auth-pages/register"
                        class="text-primary"
                        >Register</router-link
                      >
                    </div> -->
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- content-wrapper ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>
  </section>
</template>


<script>
import { required, email, minLength } from "vuelidate/lib/validators";
import { authApi } from "../../api";
import Loader from "../../components/Loader";
export default {
  name: "Login",
  data() {
    return {
      user: {
        email: "",
        password: "",
      },
      submitted: false,
      showLoader: false,
    };
  },
  components: {
    Loader,
  },
  validations: {
    user: {
      email: { required, email },
      password: { required, minLength: minLength(3) },
    },
  },

  methods: {
    async signIn() {
      this.submitted = true;

      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.showLoader = true;
      const { status, messages, data } = await authApi.login({
        ...this.user,
      });
      this.showLoader = false;
      if (status == 200) {
        localStorage.setItem("token", data.token);
        localStorage.setItem("user_id", data.user_id);
        if(data.role[0] == "admin"){
            this.$router.push("/");
        }
        else
         window.location.href = "https://shoplattice-website.codestager.com/";
      
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: "Login successfully",
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: messages,
        });
      }
    },
  },
};
</script>
