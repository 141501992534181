var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"login"},[_c('Loader',{attrs:{"loading":_vm.showLoader}}),_c('div',{staticClass:"container-scroller"},[_c('div',{staticClass:"container-fluid page-body-wrapper full-page-wrapper"},[_c('div',{staticClass:"content-wrapper d-flex align-items-center auth"},[_c('div',{staticClass:"row w-100 flex-grow"},[_c('div',{staticClass:"col-xl-4 col-lg-6 mx-auto"},[_c('div',{staticClass:"auth-form-light text-left p-4"},[_vm._m(0),_c('h4',[_vm._v("Hello! let's get started")]),_c('h6',{staticClass:"font-weight-light"},[_vm._v("Sign in to continue.")]),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.signIn.apply(null, arguments)}}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.email),expression:"user.email"}],staticClass:"form-control form-control-lg",class:{
                      'is-invalid': _vm.submitted && _vm.$v.user.email.$error,
                    },attrs:{"type":"email","id":"email","name":"email","required":""},domProps:{"value":(_vm.user.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "email", $event.target.value)}}}),(_vm.submitted && _vm.$v.user.email.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.user.email.required)?_c('span',[_vm._v("Email is required")]):_vm._e(),(!_vm.$v.user.email.email)?_c('span',[_vm._v("Email is invalid")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"password"}},[_vm._v("Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.password),expression:"user.password"}],staticClass:"form-control form-control-lg",class:{
                      'is-invalid': _vm.submitted && _vm.$v.user.password.$error,
                    },attrs:{"type":"password","id":"password","name":"password"},domProps:{"value":(_vm.user.password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "password", $event.target.value)}}}),(_vm.submitted && _vm.$v.user.password.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.user.password.required)?_c('span',[_vm._v("Password is required")]):_vm._e(),(!_vm.$v.user.password.minLength)?_c('span',[_vm._v("Password must be at least 9 characters")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"my-2 d-flex justify-content-between align-items-center"},[_vm._m(1),_c('div',{staticClass:"auth-link text-black"},[_c('router-link',{staticClass:"text-primary",attrs:{"to":"/auth-pages/forgot"}},[_vm._v("Forgot password?")])],1)]),_vm._m(2)])])])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"brand-logo"},[_c('img',{attrs:{"src":require("../../assets/images/logo-new.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-check"},[_c('label',{staticClass:"form-check-label text-muted"},[_c('input',{staticClass:"form-check-input",attrs:{"type":"checkbox"}}),_vm._v(" Keep me signed in "),_c('i',{staticClass:"input-helper"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('button',{staticClass:"btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn"},[_vm._v(" Login ")])])
}]

export { render, staticRenderFns }